.c-profil {
  display: flex;
  flex-flow: column;
  justify-content: center;

  text-align: center;
  cursor: pointer;
  padding: 10px;
  p {
    color: $primary-color;
    margin-bottom: 5px;
  }
}

.masked {
  mask-repeat: no-repeat;
  mask-size: 100%;
  mask-position: center;
  width: 220px;
  position: relative;

  &--mask_1 {
    mask-image: url('../images/Mask_photo.svg');

  }
  &--mask_2 {
    mask-image: url('../images/Mask_photo_2.svg');
  }
  &--mask_3 {
    mask-image: url('../images/Mask_photo_3.svg');


  }
}

.bg_shape {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: -1;

  background-image: url('../images/shape_profils_bg.svg');
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;

  animation-name: rorota;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  &.bg_shape_2 {
    background-image: url('../images/shape_profils_bg_2.svg');
  }
}

.picture {
  position: relative;
  width: 220px;

  padding:0 20px;
  margin: 0 auto;
}

@media screen and (max-height: 760px){
  .picture {
    max-height: 120px;
    width: 110px;
    padding: 0 10px !important;
  }
}

@media screen and (max-width: 760px){
  .picture {
    max-height: 120px;
    width: 110px;
    padding: 0 10px !important;
  }
}

@media screen and (max-width: 450px){
   .mobile-gird {
       display: flex;
       flex-flow: row wrap;
       justify-content: space-around;
   }
}
