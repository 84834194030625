.c-burger {
  position: absolute;
  left: 27px;
  top: 27px;
  display: block;
  cursor: pointer;
  text-transform: uppercase;
  z-index: 30;
}

.c-burger .c-burger__container {
  width: 35px;
  height: 30px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

.c-burger .c-burger__container.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.c-burger .c-burger__container span {
  display: block;
  position: absolute;
  height: 1px;
  width: 100%;
  background: #222;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.c-burger .c-burger__container.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.c-burger .c-burger__container span:nth-child(4) {
  top: 24px;
}

.c-burger .c-burger__container.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.c-burger .c-burger__container.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.c-burger .c-burger__container span:nth-child(2), .c-burger .c-burger__container span:nth-child(3) {
  top: 12px;
}

.c-burger .c-burger__container span:nth-child(2), .c-burger .c-burger__container span:nth-child(3) {
  top: 12px;
}
