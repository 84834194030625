.l-navigation {
  display: none;
  position: relative;
  background: white;
  width: 250px;
  height: 100%;
  text-align: center;

  @media (min-width: $medium) {
    display: block;
  }

  &__legal {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
    font-weight: 200;
    font-size: 1.2rem;
  }
}
