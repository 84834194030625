.m-nav {
  position: absolute;
  background: white;
  z-index: 10;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__header {
    z-index: 30;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 80px;
    background: white;
    text-align: center;

    @media (min-width: $medium) {
      display: none;
    }
  }

  &--mobile {
    transform: translateX(-100%);
    transition-duration: 500ms;

    &.is-active {
      transform: translateX(0);
    }

    @media (min-width: $medium) { display: none !important; }
  }

  @media (min-width: $medium) {
    display: block;
    position: relative;
    width: auto;
    height: auto;
  }

  ul {
    text-align: center;
    list-style-type: none;
    padding-left: 0;
    margin: 0;

    @media (min-width: $medium) {
      text-align: left;
      list-style-type: none;
      padding-left: 40px;
    }
  }

  li {
    padding: 10px;
    font-size: 1.4rem;
    font-weight: 100;
    cursor: pointer;

    @media (min-width: $medium) {
      padding: 5px;
    }

    &.is-active { font-weight: 700; }
  }
}
