.m-more {
  height: 100%;
  overflow: auto;
  padding-top: 70px;

  @media (min-width: $medium) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 0;
  }

  h3 {
    font-weight: 200;
    letter-spacing: 4px;
    font-size: 2.5rem;
    color: $color-base;
  }

  &__container {
    padding: 10px 0 0 0;
    text-align: center;
  }

  &__item {
    display: inline-block;
    vertical-align: top;
    margin: 20px 20px;
    max-width: 240px;

    &:last-child {

      .m-more__media {
        height: 150px;
      }

      .m-more__title-item {
        text-align: center;
        padding-bottom: 8px;
      }
    }
  }

  &__media {
    background-size: cover;
    width: 240px;
    height: 350px;

    @media (max-height: $medium) {
      width: 150px;
      height: 240px;
    }
  }

  &__title-item {
    padding-top: 10px;
    color: $color-base;
    font-size: 1.4rem;
    text-transform: uppercase;
    max-width: 150px;
  }

  &__information-item {
    color: $color-base;
    font-size: 1.2rem;
    font-weight: 200;
  }
}
