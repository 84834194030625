.shape {
  position: absolute;
  z-index: -5;
  max-width: inherit;
  &.splash_1 {
    top: 0;
    left: 0;
    width: 80%;
    max-width: 1100px;
  }
  &.splash_2 {
    top: 0px;
    right: 0px;
    width: 50%;
    max-width: 900px;
  }
  &.WWA_1 {
    top: -160px;
    left: -210px;
    width: 180%;
    z-index: -6;
    max-height: 1700px;
  }
  &.WWA_2 {
    top: -120px;
    right: -650px;
    width: 90%;
    z-index: -7;
  }
  &.team_1 {
    top: -320px;
    right: -650px;
    width: 90%;
    z-index: -7;
  }
  &.reference_1 {
    top: -320px;
    display: block;
    left: -2010px;
    width: 280%;
    z-index: -6;
    max-height: 1700px;
  }
}

@media screen and (max-height: 760px){
  .shape {
    &.splash_1 {
      top: 0;
      left: 0;
      width: 80%;
      max-width: 780px;
    }
    &.splash_2 {
      top: 0px;
      right: 0px;
      width: 50%;
      max-width: 570px;
    }
    &.WWA_1 {
      top: -160px;
      left: -210px;
      width: 160%;
      z-index: -6;
      max-height: 970px;
    }
    &.team_1 {
      top: -240px;
      right: -650px;
      max-width: 1000px;
      width: 90%;
      z-index: -7;
    }
    &.reference_1 {
    top: -120px;
    display: block;
    left: -770px;
    width: 340%;
    z-index: -6;
    max-height: 820px;
    }
  }
}

@media screen and (max-width: 760px){
  .shape {
    &.splash_1 {
      top: 0;
      left: 0;
      width: 80%;
      max-width: 780px;
    }
    &.splash_2 {
      top: 0px;
      right: 0px;
      width: 50%;
      max-width: 570px;
    }
    &.WWA_1 {
      top: -160px;
      left: -210px;
      width: 160%;
      z-index: -6;
      max-height: 970px;
    }
    &.team_1 {
      top: -240px;
      right: -650px;
      max-width: 1000px;
      width: 90%;
      z-index: -7;
    }
    &.reference_1 {
    top: -120px;
    display: block;
    left: -770px;
    width: 340%;
    z-index: -6;
    max-height: 820px;
    }
  }
}

@media screen and (max-width: 450px){
  .shape {
    &.splash_1 {
      top: 0px;
      left: -120px;
      width: 150%;
      max-width: 540px;
    }
    &.splash_2 {
      top: 0px;
      right: 0px;
      width: 55%;
      max-width: 640px;
    }
    &.WWA_1 {
      top: -160px;
      left: -210px;
      width: 560%;
      z-index: -6;
      max-height: 940px;
    }
    &.team_1 {
      top: -240px;
      right: -650px;
      max-width: 1000px;
      width: 90%;
      z-index: -7;
    }
  }
}
