.l-information {
  z-index: 50;
  position: absolute;
  top: 0;
  right: 0;
  padding: 50px 30px;
  background: #fafafa;
  width: 100%;
  height: 100%;
  text-align: left;
  transform: translateX(100%);
  transition-duration: 500ms;
  overflow: auto;

  &.is-active {
    transform: translateX(0);
  }

  @media (min-width: $medium) {
    padding: 60px 40px;
    width: 445px;
  }

  .c-burger {
    position: absolute;
    left: auto;
    right: 25px;
    top: 30px;
  }

  &__content {

    @media (min-width: $medium) {
      margin-top: 0;
    }

    h1, h2 {
      padding-right: 40px;
    }

    h1 {
      font-family: "Swiss721";
      font-weight: 700;
      font-size: 2.5rem;
      text-transform: uppercase;
    }

    h2 {
      font-family: "Swiss721";
      font-weight: 400;
      font-size: 1.6rem;
    }

    p {
      padding-top: 30px;
      text-align: justify;
      font-family: "Swiss721";
      font-weight: 200;
      font-size: 1.4rem;
      line-height: 2.8rem;
      letter-spacing: 0.5px;
    }
  }

  &__more {
    padding: 10px 0;
    margin: 0;
    list-style-type: none;

    @media (max-height: $medium) {
      right: 0;
      padding: 20px 0;
    }

    &--picto {
      .l-information__item {
        margin-top: 20px;
        padding-left: 40px;

        p {
          padding: 0;
          text-align: left;
        }

        &:before { content: ""; }
      }
    }
  }

  &__picto {
    position: absolute;
    width: 40px;
    height: 40px;
    margin-left: -45px;
    margin-top: -7px;
    background-size: cover;
    background-position: center;
  }

  &__item {
    margin-top: 5px;
    font-size: 1.4rem;
    text-align: left;
    font-family: "Swiss721";
    font-weight: 200;
  }
}
