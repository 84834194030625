.c-isotope {
  margin-top: 150px;

  @media (min-width: $medium) {
    margin-top: 0;
  }

  &__wrapper {
    padding: 0 10px;

    @media (min-width: $medium) {
      padding: 0 40px 0 0;
    }
  }

  &__item {
    width: 50%;
    margin-bottom: 35px;

    @media (min-width: $medium) {
      width: 25%;
    }

    @media (min-width: $large) {
      width: 20%;
    }

    @media (min-width: $extra-large) {
      width: 16.6%;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: cover;

      @media (min-width: $medium) {
        // width: 240px;
        // height: 350px;
        // height: 580px;
        // min-width: 240px;
      }
    }

    h2 {
      margin-top: 5px;
      margin-bottom: 0;
      font-size: 1.4rem;
      font-weight: 400;
      text-transform: uppercase;
    }

    p {
      margin: 0;
      font-size: 1.2rem;
      font-weight: 200;
    }
  }
}
