.l-project {

  &__filters {
    z-index: 9;
    position: fixed;
    top: 80px;
    padding: 20px 0 30px 0;
    background: white;
    width: 100%;
    margin: 0;
    list-style-type: none;
    margin-bottom: 40px;

    @media (min-width: $medium) {
      top: 0;
      padding: 55px 0 40px 0;
      width: 100%;
    }

    li {
      display: inline-block;
      font-size: 1.2rem;
      font-weight: 200;

      a { padding: 20px 0; }

      &.is-active {
        a {
          font-weight: 700;
        }
      }
    }
  }
}
