.m-freewall {
  margin-top: 80px;

  @media (min-width: $medium) {
    margin: 0;
  }

  &__description {
    padding: 0 30px;

    @media (min-width: $medium) {
      padding: 0;
    }

    h2 {
      margin: 15px 0px 10px;
      text-align: left;
      text-transform: uppercase;
      font-size: 1.6rem;
      font-weight: 800;
      line-height: inherit;
      text-align: center;
    }

    p {
      padding: 0;
      font-size: 1.1rem;
      font-weight: 400;
      text-align: justify;
      line-height: 1.5rem;

    }
  }

  &__item {
    position: absolute;
    width: 210px;

    &:last-child > .m-freewall__description > p { padding-bottom: 120px; }

    &.wide {
      width: auto;

      @media (min-width: $medium) {
        width: 560px;
      }

      @media (min-width: $extra-large) {
        width: 700px;
      }
    }

    &.empty {
      display: none !important;

      @media (min-width: $medium) {
        display: block !important;
      }
    }
  }
}
