@font-face {
  font-family: 'Swiss721';
  src: url('fonts/Swiss721BT-Roman.eot?#iefix') format('embedded-opentype'),  url('fonts/Swiss721BT-Roman.woff') format('woff'), url('fonts/Swiss721BT-Roman.ttf')  format('truetype'), url('fonts/Swiss721BT-Roman.svg#Swiss721BT-Roman') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Swiss721';
  src: url('fonts/Swiss721BT-Light.eot?#iefix') format('embedded-opentype'),  url('fonts/Swiss721BT-Light.woff') format('woff'), url('fonts/Swiss721BT-Light.ttf')  format('truetype'), url('fonts/Swiss721BT-Light.svg#Swiss721BT-Light') format('svg');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Swiss721';
  src: url('fonts/Swiss721BT-Bold.eot?#iefix') format('embedded-opentype'),  url('fonts/Swiss721BT-Bold.woff') format('woff'), url('fonts/Swiss721BT-Bold.ttf')  format('truetype'), url('fonts/Swiss721BT-Bold.svg#Swiss721BT-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
}
