.l-main {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &__logo {
    height: 100%;

    &--mobile {
      @media (min-width: $medium) {
        height: auto;
      }
    }

    img {
      padding: 10px 20px;
      width: 100%;
      height: 100%;

      @media (min-width: $medium) {
        padding: 20px 25px;
        width: 100%;
        height: auto;
      }
    }
  }

  &__content {
    background: white;
    flex: 1;
    overflow: auto;
    text-align: center;

    &--contact {
      img {
        margin-bottom: 20px;
        max-width: 800px;
      }

      h1 {
        font-size: 2rem;
      }

      p, a {
        font-size: 1.6rem;
        font-weight: 200;
      }

      a {
        text-decoration: underline;
      }
    }

    &--agency {
      padding-top: 80px;

      img {
        padding: 40px 0;

        &.size-medium {
          width: 100%;

          @media (min-width: $medium) {
            width: auto;
          }
        }

        &.size-full {
          padding: 0 0 40px 0;
        }
      }

      h1 {
        font-size: 2.5rem;
        text-transform: uppercase;
        font-weight: 400;
      }

      h3 {
        font-size: 1.6rem;
        font-weight: 700;
      }

      p.p1 {
        font-size: 1.4rem;
        font-weight: 400;
      }

      h3, p.p1 {
        padding: 0 30px;
        text-align: justify;

        @media (min-width: $medium) {
          padding: 0;
        }

      }
    }

    &--slider {
      overflow: hidden;

      @media (min-width: $medium) {
        padding: 0 !important;
      }
    }

    @media (min-width: $medium) {
      padding: 120px 120px 50px 10px;
      text-align: left;
    }

    &--projects {
      @media (min-width: $medium) {
        padding: 120px 80px 50px 10px;
      }
    }
  }
}
