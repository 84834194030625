.c-title {
  color: $text-grey;

  &.is-active {
    animation: color-change 500ms ease-out 1 forwards;
  }

  &--animation {
    animation: color-change-reverse 250ms ease-out 1 forwards;
  }

  &--blue {
    color: $primary-color;
  }
  &--section {
    position: absolute;
    top: 50px;
    left: 50px;
  }
}

@media screen and (max-height: 760px){
  .c-title--section {
    top: 30px !important;
    left: 30px !important;
    font-size: 70px;
  }
}

@media screen and (max-width: 760px){
  .c-title--section {
    top: 30px !important;
    left: 30px !important;
    font-size: 70px;
  }
}

@media screen and (max-width: 450px){
  .c-title--section {
    top: 20px !important;
    left: 20px !important;
    font-size: 45px;
  }
}

@keyframes color-change {
  0% { color: $first-grey; }
  100% { color: $primary-color; }
}

@keyframes color-change-reverse {
  0% { color: $primary-color; }
  100% { color: $first-grey; }
}
