/* ----------------------------- */
/* ==Forms                       */
/* ----------------------------- */
/* thanks to HTML5boilerplate and https://shoelace.style/ */

/* forms items */
form,
fieldset {
  border: none;
}

fieldset {
  padding: $spacer-medium;

  & legend {
    padding: 0 $spacer-tiny;
    border: 0;
    white-space: normal;
  }
}

label {
  display: inline-block;
  cursor: pointer;
}

[type="color"],
[type="date"],
[type="datetime-local"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="search"],
[type="submit"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"],
select,
textarea {
  white-space: nowrap;
  font-family: inherit;
  font-size: inherit;
  border: 0;
  box-shadow: 0 0 0 1px $forms-color inset;
  color: $color-base;
  vertical-align: middle;
  padding: $spacer-tiny $spacer-small;
  margin: 0;
  transition: 0.25s;
  transition-property: box-shadow, background-color, color, border;
  appearance: none;
}

[type="submit"] {
  background-color: $forms-color;
  color: $white;
  cursor: pointer;
}

input[readonly] {
  background-color: $color-muted;
}

select {
  padding-right: 2rem;
  border-radius: $border-radius;
  background-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20standalone%3D%22no%22%3F%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20style%3D%22isolation%3Aisolate%22%20viewBox%3D%220%200%2020%2020%22%20width%3D%2220%22%20height%3D%2220%22%3E%3Cpath%20d%3D%22%20M%209.96%2011.966%20L%203.523%205.589%20C%202.464%204.627%200.495%206.842%201.505%207.771%20L%201.505%207.771%20L%208.494%2014.763%20C%209.138%2015.35%2010.655%2015.369%2011.29%2014.763%20L%2011.29%2014.763%20L%2018.49%207.771%20C%2019.557%206.752%2017.364%204.68%2016.262%205.725%20L%2016.262%205.725%20L%209.96%2011.966%20Z%20%22%20fill%3D%22inherit%22/%3E%3C/svg%3E");
  background-position: right .6rem center;
  background-repeat: no-repeat;
  background-size: 1.2rem;
}

/* hiding IE11 arrow */
select::-ms-expand {
  display: none;
}

textarea {
  min-height: 5em;
  vertical-align: top;
  resize: vertical;
  white-space: normal;
}

/* 'x' appears on right of search input when text is entered. This removes it */
[type="search"] {
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

::-webkit-input-placeholder {
  color: #777;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #777;
}

input::placeholder,
textarea::placeholder {
  color: #777;
}

progress {
  width: 100%;
  vertical-align: middle;
}

// Material input

.my-form {
  display: flex;
  justify-content: center;
  flex-flow: column;
}

.material {
	position: relative;
	padding: 0;
	margin: 5px;
	border: none;
	overflow: visible;
  width: 500px;
  margin-bottom: 35px;
  textarea {
    box-sizing: border-box;
		width: 100%;
		padding: 12px 10px 8px 0px;
		border: none;
		border-radius: 0;
		box-shadow: none;
		border-bottom: 1px solid #DDD;
		font-size: 120%;
		outline: none;
		cursor: text;
    color: #FFF;
    background-color: transparent;

		&::-webkit-input-placeholder {
			transition: color 300ms ease;
      color: $gray-400;
		}
		&:not(:focus)::-webkit-input-placeholder {
			color: transparent;
		}
  }
	input {
		box-sizing: border-box;
		width: 100%;
		padding: 12px 10px 8px 0px;
		border: none;
		border-radius: 0;
		box-shadow: none;
		border-bottom: 1px solid #DDD;
		font-size: 120%;
		outline: none;
		cursor: text;
    color: #FFF;
    background-color: transparent;

		&::-webkit-input-placeholder {
			transition: color 300ms ease;
      color: $gray-400;
		}
		&:not(:focus)::-webkit-input-placeholder {
			color: transparent;
		}
	}

	hr {
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		margin: 0;
		padding: 0;
		width: 100%;
		height: 2px;
		border: none;
		background: #FFF;
		font-size: 1px;
		will-change: transform, visibility;
		transition: all 200ms ease-out;
		transform: scaleX(0);
		visibility: hidden;
		z-index: 10;
	}
	input:focus ~ hr {
		transform: scaleX(1);
		visibility: visible;
	}
  textarea:focus ~ hr {
		transform: scaleX(1);
		visibility: visible;
	}

	label {
		position: absolute;
		top: 10px;
		left: 0px;
		font-size: 120%;
		color: #FFF;
		transform-origin: 0 -150%;
		transition: transform 300ms ease;
		pointer-events: none;
	}
	input:focus ~ label,
	input:valid ~ label {
		transform: scale(0.6);
	}
  textarea:focus ~ label,
	textarea:valid ~ label {
		transform: scale(0.6);
	}
}

@media screen and (max-height: 760px){
  .material {
    width: 400px !important;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 30px !important;
    }
  }
}

@media screen and (max-width: 760px){
  .material {
    width: 400px !important;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 30px !important;
    }
  }
}

@media screen and (max-width: 450px){
  .material {
    width: 300px !important;
  }
}
