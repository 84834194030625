.royalSlider {
  width: 100% !important;
  height: 100vh !important;

  p {
    text-align: center;
    color: $color-base;
    font-size: 1.4rem;
    letter-spacing: 0;
    margin: 8px 0;
  }

  .rsImg {

    @media all and (orientation: portrait) {
      width: 100% !important;
      height: 100% !important;
      margin-left: 0 !important;
      margin-top: 0 !important;
    }
  }

  .rsOverflow {
    background: white !important;
    width: 100% !important;
    padding-top: 80px;

    @media (min-width: $medium) {
      padding-top: 0;
    }
  }

  &.rsDefault {
    background: none;

    .rsArrowIcn { background-color: transparent; }

    .rsBullets {
      bottom: 40px;
      background: none;

      .rsBullet, span {
        width: 94px;
        height: 5px;
      }

      .rsBullet {
        padding: 6px 8px 6px;
        cursor: pointer;

        span {
          background: rgba(255,255,255,0.3);
        }

        &.rsNavSelected span {
          background: white;
        }
      }

      span {
        box-shadow: 2px 1px 5px 0 rgba(0,0,0,0.15);
        border-radius: 0;
      }
    }

    .rsOverflow, .rsSlide  {
      background: $first-grey;
    }

    .rsSlide {
      background: white;

      &:first-child {
        background: white;
      }
    }
  }
}
